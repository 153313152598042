import React from 'react';

const ConfirmationPage = () => {
    return (

            <div className='confirm'>
                <div>
                    <h1>Booking has been <span>Confirmed!</span></h1>
                </div>
            </div>
        )
    }

export default ConfirmationPage;